import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';

const testimonies = [
    {
        id: 1,
        name: 'Ekworomadu Lilian',
        role: 'RT Briscoe Nigeria Plc',
        image: 'https://kambi3pl.com/public/kamsiparts/lilian.jpg',
        text: 'My experience with Kamsiparts Automotive is a very satisfying one, their prices are very competitive, and their delivery timeline is very prompt.',
      },
      {
        id: 2,
        name: 'Kola Aroyewun',
        role: 'Carviva Technologies Ltd',
        image: 'https://kambi3pl.com/public/kamsiparts/carviva.jpg',
        text: 'Our experience with Kamsiparts has been that of professionalism and good customer service in the last two and a half years of our partnership. The warranty we get from the parts we source from them has given us the market confidence that is key to us. This has therefore made Kamsiparts Automotive our main channel for sourcing for genuine quality automotive spare parts across most popular automobile brands.',
      },
      {
        id: 3,
        name: 'George Tenumah',
        role: 'Aydens Garage',
        image: 'https://kambi3pl.com/public/kamsiparts/George.jpg',
        text: 'I had an excellent experience with Kamsiparts Automotive. Their customer service was outstanding, and the staff was knowledgeable and friendly. The parts I needed were in stock and reasonably priced, and the ordering process was seamless. The quality of the parts exceeded my expectations, and my order arrived promptly and well-packaged. I highly recommend Kamsiparts Automotive for anyone in need of reliable automotive parts and exceptional service.',
      },
      {
        id: 4,
        name: 'Clara chinenye',
        role: 'Pamtech Group',
        image: 'https://kambi3pl.com/public/kamsiparts/Clara.jpeg',
        text: 'This shock have been so reliable. There has not been any return or complain from my customers.. PRT shocks all the way',
      },
    ];
    

function TestimonySection() {
  return (
    <Container fluid className="bg-light py-5">
        <hr/>
      <Container>
        <h2 className="text-center mb-4">Customer Testimonials</h2>
        <Row className="justify-content-center">
        <hr className="m-4" />
          {testimonies.map((testimonial) => (
            <Col key={testimonial.id} xs={10} md={6} lg={3} className="mb-4">
              <Card className="shadow">
                <Card.Body className="text-center">
                  <Image className="mb-3 mx-auto d-block" style={{width:'50%',objectFit:'cover', height:'120px'}} src={testimonial.image} alt={testimonial.name} roundedCircle  />
                  <Card.Title className="text-center">{testimonial.name}</Card.Title>
                  <Card.Subtitle className="text-center mb-3">{testimonial.role}</Card.Subtitle>
                  <Card.Text>{testimonial.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <hr className="m-4" />
    </Container>
    
  );
}

export default TestimonySection;
