import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemById } from '../../actions/itemActions';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './styles.module.css';

const PhotoboxProduct = ({ id }) => {
  const { items, loading } = useSelector((state) => state.itemReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItemById('api/productImg/', id));
  }, [dispatch, id]);

  const [imgHover, setImgHover] = useState(0);

  return (
    <Container>
      <Row className={styles.container}>
        <Col xs={12} md={6}>
          <div className={styles.smallImg}>
            {items.map((e, i) => (
              <img
                key={i}
                onMouseEnter={() => setImgHover(i)}
                src={e.url}
                alt={'small-img -' + i}
              />
            ))}
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className={styles.bigImg}>
            <img src={loading ? '' : items[imgHover]?.url} alt="big-img" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PhotoboxProduct;
