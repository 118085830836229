import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const VehicleDropdown = () => {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [engines, setEngines] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleMakeChange = (make) => {
    setSelectedMake(make);
    setSelectedModel("");
    setSelectedYear("");
    setModels(Object.keys(makes[make]));
  };

  const handleModelChange = (model) => {
    setSelectedModel(model);
    setSelectedYear("");
    setYears(Object.keys(makes[selectedMake][model]));
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    const data = makes[selectedMake][selectedModel][year];
    setEngines(data.engine_types);
    setVehicleTypes(data.vehicle_types);
    setShowModal(true);
  };

  const fetchMakes = async () => {
    try {
      const totalPages = 3; // Set the total number of pages you want to fetch
      let allMakes = {};
  
      // Use Promise.all to fetch data from all pages concurrently
      const promises = Array.from({ length: totalPages }, (_, index) => {
        const page = index + 1;
        const nextPage = `${process.env.REACT_APP_API_URL}api/vehiclesdataapi/?page=${page}`;
        return axios.get(nextPage);
      });
  
      const responses = await Promise.all(promises);
  
      responses.forEach((response) => {
        const pageMakes = response.data;
        Object.keys(pageMakes).forEach((make) => {
          if (!allMakes[make]) {
            allMakes[make] = pageMakes[make];
          } else {
            // Merge models and years
            Object.keys(pageMakes[make]).forEach((model) => {
              if (!allMakes[make][model]) {
                allMakes[make][model] = pageMakes[make][model];
              } else {
                allMakes[make][model] = {
                  ...allMakes[make][model],
                  ...pageMakes[make][model],
                };
              }
            });
          }
        });
      });
  
      setMakes((prevMakes) => ({ ...prevMakes, ...allMakes }));
    } catch (error) {
      console.error("Error fetching makes:", error);
    }
  };
  
  

  useEffect(() => {
    fetchMakes();
  }, []);

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Open Dropdown Modal
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Dropdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="makeDropdown">
              <Form.Label>Select Make</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => handleMakeChange(e.target.value)}
              >
                <option value="">Select Make</option>
                {Object.keys(makes).map((make) => (
                  <option key={make} value={make}>
                    {make}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="modelDropdown">
              <Form.Label>Select Model</Form.Label>
              <Form.Control
                as="select"
                disabled={!selectedMake}
                onChange={(e) => handleModelChange(e.target.value)}
              >
                <option value="">Select Model</option>
                {models.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="yearDropdown">
              <Form.Label>Select Year</Form.Label>
              <Form.Control
                as="select"
                disabled={!selectedModel}
                onChange={(e) => handleYearChange(e.target.value)}
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="engineDropdown">
              <Form.Label>Select Engine</Form.Label>
              <Form.Control as="select" disabled={!selectedYear}>
                <option value="">Select Engine</option>
                {engines.map((engine, index) => (
                  <option key={index} value={engine}>
                    {engine}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="vehicleTypeDropdown">
              <Form.Label>Select Vehicle Type</Form.Label>
              <Form.Control as="select" disabled={!selectedYear}>
                <option value="">Select Vehicle Type</option>
                {vehicleTypes.map((vehicleType, index) => (
                  <option key={index} value={vehicleType}>
                    {vehicleType}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VehicleDropdown;
