import axios from "axios";
import {
  GET_USER,
  USER_LOADING,
  USER_CODE_LOADING,
  GET_USER_CODE,
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  // UPDATE_TRANSACTION_STATUS,
} from '../types/companyUserTypes';

export const getUser = (id) => async (dispatch) => {
  dispatch({
    type: USER_LOADING
  });

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/${id}/`);
    dispatch({
      type: GET_USER,
      payload: res.data
    });
  } catch (error) {
    // Handle error
  }
};

export const getUserCode = (id) => async (dispatch) => {
  dispatch({
    type: USER_CODE_LOADING
  });

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user_code/${id}/`);
    dispatch({
      type: GET_USER_CODE,
      payload: res.data
    });
  } catch (error) {
    // Handle error appropriately
  }
};

export const getTransactions = () => async (dispatch) => {
  console.log('getTransactions action dispatched');
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/bank-transactions/`);
    console.log('getTransaction fetching API:',res.data);
    dispatch({ type: GET_TRANSACTIONS, payload: res.data });


  } catch (error) {
    console.error('Error in getTransactions:', error);
    // Handle error
  }
};
// add transactions
export const addTransaction = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/add-transaction/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({ type: ADD_TRANSACTION, payload: res.data });
  } catch (error) {
    console.error('Error adding transaction:', error);
    // Handle error
  }
};


// // add transactions
// export const addBankTransaction = (formDataWithFile) => async (dispatch) => {
//   try {
//     const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/bank-transactions/`, formDataWithFile, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     dispatch({ type: ADD_TRANSACTION, payload: res.data });
//   } catch (error) {
//     console.error('Error adding transaction:', error);
//     // Handle error
//   }
// };

// export const updateTransactionStatus = (transactionId, updatedStatus) => async (dispatch) => {
//   try {
//     const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/bank-transactions/${transactionId}/`, { status: updatedStatus });
//     dispatch({ type: UPDATE_TRANSACTION_STATUS, payload: res.data });
//   } catch (error) {
//     // Handle error
//   }
// };
// Other actions like updating transaction status can be added here
