import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getItemById, deleteItem } from "../../actions/itemActions";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Pagination, Table, Form } from "react-bootstrap";
import styles from "./styles.module.css";
import ProductForm from "../../components/ProductForm/ProductForm";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const SellerProductList = () => {
  const location = useLocation();
  const { storeId } = location.state;
  const dispatch = useDispatch();
  const { items, loading } = useSelector((state) => state.itemReducer);
  const [showForm, setShowForm] = useState(false);
  const [actionForm, setActionForm] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(getItemById("api/product/seller/", storeId));
  }, [dispatch, storeId]);

  const showFormPanel = (action, dataProduct) => {
    setActionForm(action);
    setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 500);

    setEditForm(dataProduct);
  };

  const deleteProduct = (id, thumbnail) => {
    if (window.confirm("Delete the item?")) {
      const filename = thumbnail.split("/").at(-1);

      dispatch(deleteItem(id, filename));
      window.location.replace("/seller_product_list");
    }
  };

  const cancel = () => {
    setShowForm(false);
  };


  const filteredItems = items.filter((item) => {
    const searchFields = [
      item.title?.toLowerCase() || '',
      item.price?.toString().toLowerCase() || '',
      item.category?.toLowerCase() || '',
      item.condition?.toLowerCase() || '',
      item.sku?.toLowerCase() || '',
      item.part_no?.toLowerCase() || '',
    ];
  
    return searchFields.some((field) => field.includes(searchTerm.toLowerCase()));
  });
  
  const totalItems = filteredItems.length;
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Logic for displaying items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  

  



  return (
    <div>
      <Navbar />
      <div className={styles.body}>
        <h1>Product List</h1>

        <div className={styles.addButton}>
          {showForm ? (
            <span></span>
          ) : (
            <button onClick={() => showFormPanel("Add", {})}>Add Product</button>
          )}
        </div>

        {showForm ? (
          <ProductForm
            cancel={() => {
              cancel();
            }}
            storeId={storeId}
            actiontype={actionForm}
            editForm={editForm}
          />
        ) : (
          <span></span>
        )}

        <Form.Group controlId="search">
        <Form.Control
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>

        {showForm ? (
          <span></span>
        ) : (
          
          <Table responsive>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>B2C Price</th>
                <th>Weight</th>
                <th>Part No</th>
                <th>Sku</th>
                <th>Vehicle Set</th>
                <th>Description</th>
                <th>Category</th>
                <th>Price</th>
                <th>Thumbnail</th>
                <th>Preview Images</th>
                <th>Stock</th>
                <th>Condition</th>
                <th>Rating</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
              <tr>
                <td>Loading . . . </td>
              </tr>
            ) : (
              currentItems.map((e, i) => {
                console.log(e);
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{`${
                      (e.title ?? '').toString().length <= 25
                        ? e.title ?? ''
                        : (e.title ?? '').substring(0, 25) + " . . . "
                    }`}</td>
                    <td>{e.gen_price}</td>
                    <td>{e.weight}</td>
                    <td>{e.part_no}</td>
                    <td>{e.sku}</td>
                    <td>
                      {Array.isArray(e.vehicleSets) ? (
                        e.vehicleSets.map((vehicleSet, index) => (
                          <div key={index}>
                            <strong>Make:</strong> {vehicleSet.make}<br />
                            <strong>Model:</strong> {vehicleSet.model}<br />
                            <strong>Year Range:</strong> {vehicleSet.year.start === vehicleSet.year.end
                              ? vehicleSet.year.start
                              : `${vehicleSet.year.start} - ${vehicleSet.year.end}` || "N/A"}<br />
                            <strong>Engine:</strong> {vehicleSet.engine}<br />
                            <strong>Vehicle Type Range: </strong> {vehicleSet.vehicle_type.start === vehicleSet.vehicle_type.end 
                              ? vehicleSet.vehicle_type.start
                              : `${vehicleSet.vehicle_type.start} - ${vehicleSet.vehicle_type.end}` || "N/A"}<br />
                          </div>
                        ))
                      ) : (
                        "No Vehicle Data Set Displayed Because New Database Migration Happened"
                      )}
                    </td>
                    <td>{`${
                      (e.description ?? '').toString().length <= 25
                        ? e.description ?? ''
                        : (e.description ?? '').substring(0, 25) + " . . . "
                    }`}</td>
                    <td>{e.category}</td>
                    <td>${e.price}</td>
                    <td>
                      <a href={e.thumbnail}>see</a>
                    </td>
                    <td>
                      <Link to={"/previewImages"} state={{ productId: e.id }}>
                        see
                      </Link>
                    </td>
                    <td>{e.stock}</td>
                    <td>{e.condition}</td>
                    <td>
                      <Rating
                        readonly={true}
                        ratingValue={e.rating}
                        size={20}
                      />
                    </td>
                    <td className={styles.actionButton}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => showFormPanel("Edit", e)}
                        style={{ cursor: 'pointer', padding:'5px', marginRight: '5px' , fontSize: '20px'}}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => deleteProduct(e.id, e.thumbnail)}
                        style={{ cursor: "pointer", padding:'5px', fontSize: '20px' }}
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
          </Table>
        )}

        <Pagination>
          {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }).map((_, index) => (
            <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
      <Footer />
    </div>
  );
};

export default SellerProductList;
