import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../actions/companyAuthActions';
import { getUser, getUserCode } from '../../actions/companyUserActions';
import { Container, Card, Row, Button, Navbar, Col, Modal, Form, Table, Alert, Pagination} from 'react-bootstrap'; 
import { PaystackButton } from 'react-paystack';


const CompanyRoleDashboard = () => {
  const { user, userCode } = useSelector((state) => state.companyUserReducer);
  const { token } = useSelector((state) => state.companyAuthReducer);
  const dispatch = useDispatch();

  const [dataFetched, setDataFetched] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null); // State for success message

  const [transactions, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 5;

  useEffect(() => {
    fetchDataIfTokenExists();
  }, [token, dataFetched]);

  useEffect(() => {
    if (user && user.id) {
      fetchTransactionHistory(user.id);
    }
  }, [user, currentPage]);

  const fetchDataIfTokenExists = () => {
    if (token && !dataFetched) {
      try {
        const tokenParse = JSON.parse(token);
        if (tokenParse?.id) {
          dispatch(getUser(tokenParse.id));
          dispatch(getUserCode(tokenParse.id));
          setDataFetched(true);
        }
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.replace('/');
  };

  const handlePaymentSuccess = (reference) => {
    const formData = new FormData();
    formData.append('amount', amount);
    formData.append('reference', reference.reference);
    formData.append('user_id', user.id); // Add user_id to the request payload
    formData.append('username', user.username); // Add username to the request payload
    formData.append('unique_code', userCode.unique_code); // Add unique_code to the request payload
  
    console.log('Request payload:', {
      amount,
      reference: reference.reference,
      user_id: user.id,
      username: user.username,
      unique_code: userCode.unique_code
    });
  
    return fetch(`${process.env.REACT_APP_API_URL}api/add-transaction/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Error adding transaction: ${response.statusText}`);
      }
    })
    .then((responseData) => {
      console.log('Transaction added successfully:', responseData);
      setShowModal(false);

      setSuccessMessage(`Successfully added: $${amount} to your wallet, refresh your browser to see your blance update`);
      setTimeout(() => {
        setSuccessMessage(null); // Clear success message after 7 seconds
      }, 7000);
    })
    .catch((error) => {
      console.error('Error adding transaction:', error);
    });
  };

  const fetchTransactionHistory = (userId) => {
    setLoading(true);
    
    fetch(`${process.env.REACT_APP_API_URL}api/fetch-user-transaction-history/?user_id=${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Error fetching transaction history: ${response.statusText}`);
      }
    })
    .then((responseData) => {
      console.log('Transaction history data:', responseData);
      setTransactionHistory(responseData.transaction_history);
      setLoading(false);
    })
    .catch((error) => {
      setError(error.message);
      setLoading(false);
    });
  };

  const handlePaymentClose = () => {
    console.log('Payment closed.');
  };

  const generateTransactionReference = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000);
    const userUniqueCode = userCode ? userCode.unique_code : '';
    const reference = `TXN-${userUniqueCode}-${timestamp}-${random}`;
    const sanitizedReference = reference.replace(/[^a-zA-Z0-9\-.=]/g, '');
    return sanitizedReference;
  };

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container fluid>
      {/* Your JSX */}
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">Kamsiparts</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Signed in as: <a href="#login"> {user ? user.username : 'Loading...'}</a> **
              <Button variant="danger" onClick={handleLogout}>Logout</Button>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-4">
        <Card>
          <Card.Header>
            <h3>Welcome, {user ? user.username : 'Loading...'}:</h3>
            <span>Unique Code: <b>{userCode ? userCode.unique_code : 'Loading...'}</b></span>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card.Title>Your Details</Card.Title>
                {user ? (
                  <>
                    <Card.Text><strong>Username:</strong> {user.username}</Card.Text>
                    <Card.Text><strong>User ID:</strong> {user.id}</Card.Text>
                    <Card.Text><strong>Phone:</strong> {user.phone}</Card.Text>
                    <Card.Text><strong>Address:</strong> {user.address}</Card.Text>
                    <Card.Text><strong>Role:</strong> {user.role ? user.role.role_name : 'Loading...'}</Card.Text>
                  </>
                ) : (
                  <p>Loading...</p>
                )}
              </Col>
              <Col md={6}>
                <Card>
                  {successMessage && (
                    <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
                      {successMessage}
                    </Alert>
                  )}
                  <Card.Header>Wallet Balance</Card.Header>
                  <Card.Body>
                    <h2>${userCode ? userCode.balance : 'Loading...'}</h2>
                    <Button variant="success" onClick={() => setShowModal(true)}>Add Money</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* Transaction History table */}
            <Row className="mt-4">
              <Col>
                <h3>Transaction History</h3>
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : transactions.length === 0 ? (
                  <p>No transactions found</p>
                ) : (
                  <>
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTransactions.map((transaction, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>${transaction.amount}</td>
                              <td>{transaction.description}</td>
                              <td>{transaction.date}</td>
                              <td>{transaction.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    <Pagination>
                      {Array.from({ length: Math.ceil(transactions.length / transactionsPerPage) }, (_, i) => (
                        <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                          {i + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Money</Modal.Title>
        </Modal.Header>
        <Form.Group controlId="amount">
          <Form.Label>Amount to Add</Form.Label>
          <Form.Control
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            required
          />
        </Form.Group>
        <Modal.Body>
          <PaystackButton
            text="Pay Now"
            className="paystack-button"
            onSuccess={handlePaymentSuccess}
            onClose={handlePaymentClose}
            embed={false}
            reference={generateTransactionReference()}
            email={user ? user.email : ''}
            amount={amount}
            publicKey="pk_test_f4b82137210eebb9bd0994d309bf17abb1eb9b3c"
            channels={['card']}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CompanyRoleDashboard;
