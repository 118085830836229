import React from 'react';
import { Helmet } from 'react-helmet'; 
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './AboutUs.css';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const AboutUs = () => {
  return (
    <div>
    <Navbar />
    <Helmet>
        <title>Kamsiparts Automotive | Best Car Parts in Nigeria & Lagos State</title>
        <meta
          name="description"
          content="Kamsiparts Automotive offers genuine car parts in Nigeria and Lagos State. Reliable, safe, and convenient for vehicle owners."
        />
        <meta
          name="keywords"
          content="Car parts in Nigeria, Car parts in Lagos State, Kamsiparts Automotive, original auto parts, TCHIKAMALOR Global Nig. Ltd, Kamsiparts platform"
        />
      </Helmet>
    <Row className="mb-4 about-section">
            <h1 className="text-center fw-bold mb-3">About Kamsiparts Automotive</h1>
            <p className="text-center lead">
            Kamsiparts Automotive is a premier B2B2C auto parts distribution platform in Nigeria and Lagos State, providing an interactive, safe, and efficient solution for vehicle owners.
            </p>
    </Row>
  
    <Container className="about-page my-5">
      

      {/* Page Header */}
     

      {/* Who We Are Section */}
      <Row className="mb-5">
        <Col md={12} lg={6} className="mb-4">
          <Card className="shadow-sm p-4 rounded border-0 aboutuscard">
            <Card.Body>
              <Card.Title className="fw-bold">Who We Are</Card.Title>
              <Card.Text>
                Kamsiparts Automotive, an affiliate of TCHIKAMALOR Global Nig. Ltd., was incorporated in April 2021 with the mission to provide reliable car parts in Nigeria and beyond. With Kamsiparts Automotive INC formation in Delaware, USA, in 2024, we aim to set a new standard in auto parts accessibility, focusing on safety, affordability, and genuine parts sourcing.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* What We Do Section */}
        <Col md={12} lg={6} className="mb-4">
          <Card className="shadow-sm p-4 rounded border-0 aboutuscard">
            <Card.Body>
              <Card.Title className="fw-bold">What We Do</Card.Title>
              <Card.Text>
                As a top solution for car parts in Lagos State and across Nigeria, Kamsiparts utilizes advanced technology to solve common issues with sourcing original parts. In partnership with Tecdoc and through data-driven vehicle part fitment by VIN, we ensure our customers find the exact part they need with warranties and quality assurance.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col> 
      </Row>
      <hr className="custom-hr" />
       
      {/* Problems We Solve Section */}
      <Row className="mt-4 mb-5">
        <Col>
          <Card className="shadow-sm p-4 rounded border-0 aboutuscard">
            <Card.Body>
              <Card.Title className="fw-bold">The Problem We Solve</Card.Title>
              <Card.Text>
                Many Nigerian car owners face challenges finding quality replacement parts, resulting in:
                <ul>
                  <li>Frequent breakdowns due to low-quality parts</li>
                  <li>Higher rates of road accidents</li>
                  <li>Limited access to reliable inventories and poor product compatibility</li>
                </ul>
              </Card.Text>
              <Card.Text>
                Our platform focuses on genuine parts sourcing, enhanced safety, and convenient purchasing, specifically addressing the unique needs of the Nigerian market.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Solutions We Provide Section */}
      <Row className="mb-5">
        <Col>
          <Card className="shadow-sm p-4 rounded border-0 aboutuscard">
            <Card.Body>
              <Card.Title className="fw-bold">The Solution We Provide</Card.Title>
              <Card.Text>
                Our B2B2C platform provides Nigerian car owners with:
                <ul>
                  <li>Access to trusted, genuine auto parts with built-in warranties</li>
                  <li>Advanced technology for compatibility and part verification</li>
                  <li>Convenient services including AI, embedded finance, and last-mile delivery</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* What Sets Us Apart Section */}
      <Row className="mb-5">
        <Col>
          <Card className="shadow-sm p-4 rounded border-0 aboutuscard">
            <Card.Body>
              <Card.Title className="fw-bold">What Sets Us Apart</Card.Title>
              <Card.Text>
                Kamsiparts offers:
                <ul>
                  <li>Exclusive in-house APIs for vehicle data and product listings</li>
                  <li>SaaS API services compatible with third-party plugins</li>
                  <li>Proprietary infrastructure for seamless and reliable services</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr className="custom-hr" />
      {/* Contact Us Section */}
      <Row className="mb-5">
        <Col>
          <Card className="shadow-sm p-4 rounded border-0 aboutuscard">
            <Card.Body>
              <Card.Title className="fw-bold">Contact Us</Card.Title>
              <Card.Text>
                <strong>Phone:</strong> <br />
                Nigeria: +2349098370120 <br />
                USA: +1 3024691130 <br />
                Fax: +1 3024691140 <br /><br />
                <strong>Email:</strong> <a href="mailto:info@kamsiparts.com">info@kamsiparts.com</a> <br /><br />
                <strong>Nigeria Office:</strong><br />
                Operations: Zone D10A, 72<br />
                Sales: Zone D8, 85 ASPMDA Shopping Complex, Int'l Trade Fair, Ojo, Lagos<br /><br />
                <strong>USA Office:</strong><br />
                Kamsiparts Automotive INC, 74 E Glenwood Ave, #5396, Smyma, DE 19977, Delaware, USA
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {/* Footer */}
      <Row className="text-center">
        <Col>
          <Button variant="dark" className="shadow-sm">Learn More</Button>
        </Col>
      </Row>
    </Container>
    <Footer />
    </div>
  );
};

export default AboutUs;
