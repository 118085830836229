import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import styles from './styles.module.css';

const ProductCard = ({ item }) => {
  // Fetch user token from localStorage
  const tokenString = localStorage.getItem('token');
  const token = tokenString ? JSON.parse(tokenString) : null;
  // Check if the user is logged in and has a role
  const tokenReady = token !== null && token !== undefined && token.role && token.role.id !== undefined;


  return (
    <Link to={'/detail_product'} style={{ textDecoration: 'none' }} state={{ item: item }}>
      <Card className={`${styles.container}`} style={{ height: '95%'}}>
        <Card.Img
          src={item.thumbnail}
          alt={item.thumbnail}
          style={{ objectFit: 'cover', height: '100px' }}
        />
        <Card.Body>
          <Card.Title>
            {item.title?.length > 20 ? item.title?.substring(0, 20) + '...' : item.title}
          </Card.Title>
          <Card.Text className="mt-2 mb-2" dangerouslySetInnerHTML={{ __html: item.description?.length > 20 ? item.description?.substring(0, 20) + '...' : item.description }} />
          {/* Check user role and display price accordingly */}
          { tokenReady && token.role && (token.role.id === 1 || token.role.id === 4 || token.role.id === 2 || token.role.id === 3) ? (
            <Card.Text>₦{item.price}</Card.Text>
          ) : (
            <Card.Text>₦{item.gen_price}</Card.Text>
          )}
        </Card.Body>
      </Card>
    </Link>
  );
};

export default ProductCard;
