import {
    GET_USER,
    USER_CODE_LOADING,
    GET_USER_CODE,
    USER_LOADING,
    GET_TRANSACTIONS,
    ADD_TRANSACTION,
    UPDATE_TRANSACTION_STATUS,
  } from '../types/companyUserTypes';
  
  const initialState = {
    user: [],
    loading: true,
    transactions: [],
  };
  
  export const companyUserReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_USER:
        return {
          ...state,
          user: payload,
          loading: false
        };
      case GET_USER_CODE:
        return {
          ...state,
          userCode: payload,
          loading: false
        };
      case USER_LOADING:
      case USER_CODE_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_TRANSACTIONS:
        return {
          ...state,
          userCode: payload,
          transactions: payload,
        };

      case ADD_TRANSACTION:
        return {
          ...state,
          transactions: [...state.transactions, payload],
        };
      case UPDATE_TRANSACTION_STATUS:
        return {
            ...state,
            transactions: state.transactions.map(transaction =>
            transaction.id === payload.id ? { ...transaction, status: payload.status } : transaction
            ),
        };
      // Handle other action types as needed
  
      default:
        return state;
    }
  };
  