import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-quill/dist/quill.snow.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Provider } from 'react-redux'; // Import the Provider component
import store from './store'; // Import your Redux store

ReactDOM.createRoot(document.getElementById('root')).render( // Use ReactDOM.createRoot instead of ReactDOM.render
  <React.StrictMode>
    <Provider store={store}> {/* Wrap your App component with Provider and pass your Redux store */}
      <App />
    </Provider>
  </React.StrictMode>
);
