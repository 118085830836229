import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { emptyItem, getItemByCategory, getItems } from "../../actions/itemActions";
import { filterAll, filterCondition, filterPrice, filterPriceAndCondition, filterPriceAndRating, filterRating, filterRatingAndCondition, resetFilter } from "../../actions/filterActions";
import Navbar from "../../components/Navbar/Navbar";
import ProductCard from "../../components/ProductBanner/ProductCard/ProductCard";
import { useEffect, useState } from "react";

import FilterBox from "../../components/FilterBox/FilterBox";
import ProductLayout from "../../components/ProductLayout/ProductLayout";
import Footer from "../../components/Footer/Footer";
import Pagination from "../../components/Pagination/Pagination";
import { Container, Row, Col, Alert } from "react-bootstrap";

const Product = () => {
  const location = useLocation();
  const { state } = location;
  const category = state ? state.category : "";
  const keyword = state ? state.keyword : "";
  const { items, loading } = useSelector((state) => state.itemReducer);
  const { products } = useSelector((state) => state.filterReducer);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [error, setError] = useState(""); // New state for error handling

  const applyFilter = (minprice, maxprice, rating, condition) => {
    dispatch(emptyItem());

    // Check if any filter fields are filled
    const isFilterApplied = minprice || maxprice || rating || condition;
    
    if (!isFilterApplied) {
      // Set error message if no filters are applied
      setError("Please fill in the price range you are searching for and apply filters again...");
      return;
    }

    setError(""); // Clear error if filters are applied

    if (minprice !== "" && maxprice !== "" && rating === "" && condition === "") {
      dispatch(filterPrice(minprice, maxprice));
    } else if (minprice !== "" && maxprice === "" && rating === "" && condition === "") {
      dispatch(filterPrice(minprice, maxprice));
    } else if (minprice === "" && maxprice !== "" && rating === "" && condition === "") {
      dispatch(filterPrice(minprice, maxprice));
    } else if (minprice === "" && maxprice === "" && rating !== "" && condition === "") {
      dispatch(filterRating(rating));
    } else if (minprice === "" && maxprice === "" && rating === "" && condition !== "") {
      dispatch(filterCondition(condition));
    } else if (minprice !== "" && maxprice !== "" && rating !== "" && condition === "") {
      dispatch(filterPriceAndRating(minprice, maxprice, rating));
    } else if (minprice !== "" && maxprice !== "" && rating === "" && condition !== "") {
      dispatch(filterPriceAndCondition(minprice, maxprice, condition));
    } else if (minprice === "" && maxprice === "" && rating !== "" && condition !== "") {
      dispatch(filterRatingAndCondition(rating, condition));
    } else if (minprice === "" && maxprice !== "" && rating !== "" && condition !== "") {
      dispatch(filterAll(0, maxprice, rating, condition));
    } else if (minprice !== "" && maxprice === "" && rating !== "" && condition !== "") {
      dispatch(filterAll(minprice, 0, rating, condition));
    } else if (minprice === "" && maxprice !== "" && rating !== "" && condition === "") {
      dispatch(filterPriceAndRating(0, maxprice, rating));
    } else if (minprice !== "" && maxprice === "" && rating !== "" && condition === "") {
      dispatch(filterPriceAndRating(minprice, 0, rating));
    } else if (minprice === "" && maxprice !== "" && rating === "" && condition !== "") {
      dispatch(filterPriceAndCondition(0, maxprice, condition));
    } else if (minprice !== "" && maxprice === "" && rating === "" && condition !== "") {
      dispatch(filterPriceAndCondition(minprice, 0, condition));
    } else if (minprice !== "" && maxprice !== "" && rating !== "" && condition !== "") {
      dispatch(filterAll(minprice, maxprice, rating, condition));
    } else {
        dispatch(resetFilter());
        if (category === "") {
          if (keyword === "") {
            dispatch(getItems('api/product'));
          } 
        } else {
          dispatch(getItemByCategory(`api/product/find/category/${category}/`));
        }
      } 
    setCurrentPage(1);
  }

  let dataProduct;
  if (products.length === 0) {
    dataProduct = items;
  } else {
    dataProduct = products;
  }
  
  useEffect(() => {
    if (category === '') {
      if (keyword === '') {
        dispatch(getItems('api/product/'))
      } 
    } else {
      dispatch(getItemByCategory(category));
    }
  }, [dispatch, category, keyword]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(dataProduct) ? dataProduct.slice(indexOfFirstItem, indexOfLastItem) : [];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  return (
    <div>
      <Navbar />
  
      <Container fluid>
        <Row>
          <Col xs={4} md={3} lg={2} className="mb-4">
            <FilterBox applyFilter={applyFilter} />
          </Col>

          <Col xs={12} md={7} lg={10}>
            <ProductLayout category={category}>
              {loading ? (
                <h1>Loading...</h1>
              ) : currentItems.length === 0 ? (
                <h1>
                    <a href="https://wa.link/9j108q" class="btn btn-primary font-weight-bold text-white" 
                      style={{ fontSize: '24px', padding: '15px 30px', marginTop: '20px' }} >
                        Not Yet Listed, Click to Order Directly
                    </a>
                </h1>
              ) : (
                currentItems.map((e, i) => (
                  <ProductCard key={i} item={e} />
                ))
              )}
            </ProductLayout>
  
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(dataProduct.length / itemsPerPage)}
              paginate={paginate}
            />
          </Col>
          {error && (
              <Row>
                <Col>
                  <Alert variant="danger">
                    {error}
                  </Alert>
                </Col>
              </Row>
          )}
        </Row>
        
      </Container>

      <Footer />
    </div>
  );
}

export default Product;
