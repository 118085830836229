import { Card } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import styles from './styles.module.css';

const DescriptionBoxProduct = ({ item }) => {
  // Fetch user token from localStorage
  const tokenString = localStorage.getItem('token');
  const token = tokenString ? JSON.parse(tokenString) : null;
  console.log('Parsed Token:', token);

  // Check if the user is logged in and has a role
  const tokenReady = token !== null && token !== undefined && token.role && token.role.id !== undefined;
  console.log('Token Ready:', tokenReady);  

  return (
    <Card className={styles.container} style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
      <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{item.category}</Card.Subtitle>
        <Rating readonly={true} ratingValue={item.rating} size={20} />
        
        {/* Check user role and display price accordingly */}
        { tokenReady && token.role && (token.role.id === 1 || token.role.id === 4 || token.role.id === 2 || token.role.id === 3) ? (
          <Card.Text>₦{item.price}</Card.Text>
        ) : (
          <Card.Text>₦{item.gen_price}</Card.Text>
        )}

        <Card.Text>SKU No: {item.sku}</Card.Text>
        <Card.Text dangerouslySetInnerHTML={{ __html: item.description }} />
      </Card.Body>
    </Card>
  );
};

export default DescriptionBoxProduct;
