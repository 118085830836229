import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { addItem, updateItem } from "../../actions/itemActions";
import axios from "axios";
import { Alert, Container, Form, Button, Col, Row, Image } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 


const categoryData = [
  {
    id: 1,
    category: "Shock Absorbers",
  },
  {
    id: 2,
    category: "Brakes, Suspension & Steering",
  },
  {
    id: 3,
    category: "Batteries and Chargers",
  },
  {
    id: 4,
    category: "Sensors",
  },
  {
    id: 5,
    category: "Engine & Drivetrain",
  },
  {
    id: 6,
    category: "Filters",
  },
  {
    id: 7,
    category: "Auto Body Parts & Mirrors",
  },
  {
    id: 8,
    category: "Air Filters & Intake Systems", 
  },
  {
    id: 9,
    category: "Fuel systems & Components", 
  },
];

const ProductForm = ({ storeId, actiontype, editForm, cancel }) => {
  const { loading } = useSelector((state) => state.itemReducer);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    title: "",
    sku: "",
    description: "",
    category: "",
    price: "",
    gen_price: "",
    weight: "",
    part_no: "",
    thumbnail: "",
    stock: "",
    condition: "",
    vehicleSets: [
      {
        make: '',
        model: '',
        year: {
          start: '',
          end: '',
        },
        engine: '',
        vehicle_type: {
          start: '',
          end: '',
        },
      },
    ],
    height: "",
    dim_width: "",
    length: "",
  });
  // definng two helper functions, getEngineTypes and getVehicleTypes
  const getEngineTypes = (make, model, year) => {
    if (year.start && year.end) {
      // Year is a range, loop through the years
      const startYear = parseInt(year.start);
      const endYear = parseInt(year.end);
      const engineTypes = [];
  
      for (let i = startYear; i <= endYear; i++) {
        engineTypes.push(...vehicleData[make]?.[model]?.[i]?.engine_types || []);
      }
  
      return Array.from(new Set(engineTypes)); // Remove duplicates using Set
    } else {
      // Single year
      return vehicleData[make]?.[model]?.[year]?.engine_types || [];
    }
  };
  
  const getVehicleTypes = (make, model, year) => {
    if (year.start && year.end) {
      const startYear = parseInt(year.start);
      const endYear = parseInt(year.end);
      const vehicleTypes = [];
  
      for (let i = startYear; i <= endYear; i++) {
        vehicleTypes.push(...vehicleData[make]?.[model]?.[i]?.vehicle_types || []);
      }
  
      return Array.from(new Set(vehicleTypes));
    } else {
      return vehicleData[make]?.[model]?.[year]?.vehicle_types || [];
    }
  };
  
  
  const [rating, setRating] = useState(0);
  const [thumbnailImgFile, setThumbnailImgFile] = useState(null);
  const [thumbnailImgPreviewURL, setThumbnailImgPreviewURL] = useState("");

  const [formError, setFormError] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const {
    title,
    sku,
    description,
    category,
    price,
    gen_price,
    weight,
    part_no,
    thumbnail,
    stock,
    condition,
    vehicleSets,
    length,
    dim_width,
    height,
  } = formData;



  const [vehicleData, setVehicleData] = useState([]);
 
  const addMore = () => {
    setFormData({
      ...formData,
      vehicleSets: [...formData.vehicleSets, { make: "", model: "", year: "", engine: "", vehicle_type: "" }],
    });
  };
  

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const totalPages = 8; 
        let allVehicleData = {};

        
        const promises = Array.from({ length: totalPages }, (_, index) => {
          const page = index + 1;
          const nextPage = `${process.env.REACT_APP_API_URL}api/vehiclesdataapi/?page=${page}`;
          return axios.get(nextPage);
        });

        const responses = await Promise.all(promises);

        responses.forEach((response) => {
          const pageVehicleData = response.data;
          Object.keys(pageVehicleData).forEach((make) => {
            if (!allVehicleData[make]) {
              allVehicleData[make] = pageVehicleData[make];
            } else {
              Object.keys(pageVehicleData[make]).forEach((model) => {
                if (!allVehicleData[make][model]) {
                  allVehicleData[make][model] = pageVehicleData[make][model];
                } else {
                  allVehicleData[make][model] = {
                    ...allVehicleData[make][model],
                    ...pageVehicleData[make][model],
                  };
                }
              });
            }
          });
        });

        setVehicleData(allVehicleData);
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    };

    fetchVehicleData();
  }, []);

  useEffect(() => {
    console.log('editForm:', editForm);
    console.log('vehicleData:', vehicleData);
    if (
      Object.keys(editForm).length !== 0 &&
      Object.keys(vehicleData).length !== 0
    ) {
      const updatedFormData = { ...editForm };
    
      // Update vehicleSets
      if (editForm.vehicleSets) {
        updatedFormData.vehicleSets = editForm.vehicleSets.map((set) => {
          // Perform any necessary transformations here if needed
          return set;
        });
      }
    
      setFormData(updatedFormData);
      setRating(editForm.rating);
    }
    // console.log('Vehicle Data:', vehicleData);
  }, [editForm, vehicleData]);



  const handleMakeChange = (e, index) => {
    const newVehicleSets = [...formData.vehicleSets];
    newVehicleSets[index].make = e.target.value;
    setFormError({ ...formError, [`make-${index}`]: "" });
    setFormData({ ...formData, vehicleSets: newVehicleSets });
  };
  
  const handleModelChange = (e, index) => {
    const newVehicleSets = [...formData.vehicleSets];
    newVehicleSets[index].model = e.target.value;
    console.log('New Vehicle Sets:', newVehicleSets);
    setFormData({ ...formData, vehicleSets: newVehicleSets });
  };
  
  const handleYearChange = (e, index, startOrEnd) => {
    const newVehicleSets = [...formData.vehicleSets];
    
    if (!newVehicleSets[index].year) {
      newVehicleSets[index].year = {
        start: '',
        end: '',
      };
    }
  
    if (startOrEnd === 'start') {
      newVehicleSets[index].year.start = e.target.value;
    } else {
      newVehicleSets[index].year.end = e.target.value;
    }
    console.log('New Vehicle Sets:', newVehicleSets);
    console.log('Selected Engine Type:', e.target.value);
    setFormData({ ...formData, vehicleSets: newVehicleSets });
  };
  
  
  const handleEngineTypeChange = (e, index) => {
    const newVehicleSets = [...formData.vehicleSets];
    newVehicleSets[index].engine = e.target.value;
    setFormData({ ...formData, vehicleSets: newVehicleSets });
  };
  
  const handleVehicleTypeChange = (e, index, startOrEnd) => {
    const newVehicleSets = [...formData.vehicleSets];
    if (!newVehicleSets[index].vehicle_type) {
      newVehicleSets[index].vehicle_type = {
        start: '',
        end: '',
      };
    }
  
    if (startOrEnd === 'start') {
      newVehicleSets[index].vehicle_type.start = e.target.value;
    } else {
      newVehicleSets[index].vehicle_type.end = e.target.value;
    }
  
    setFormData({ ...formData, vehicleSets: newVehicleSets });
  };
  
  
  

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleRatig = (rate) => {
    setRating(rate);
  };

  const onImageChange = (e) => {
    let files = e.target.files;
    if (FileReader && files && files.length) {
      const previewURL = URL.createObjectURL(files[0]);
      setThumbnailImgPreviewURL(previewURL);
      setThumbnailImgFile(files[0]);
    }
  };

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
    ],
  };
  
  const quillFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background',
  ];
  

  const onSubmit = (e) => {
    e.preventDefault();

    if (!loading) {
      const data = {
        storeId: storeId,
        title: title,
        sku: sku,
        gen_price: gen_price,
        weight: weight,
        part_no: part_no,
        description: description,
        category: category,
        price: price,
        thumbnail: thumbnailImgFile || editForm.thumbnail,  // Retain original thumbnail if not updating
        stock: stock,
        condition: condition,
        rating: rating,
        vehicleSets: vehicleSets,
        height: height,
        dim_width: dim_width,
        length: length,
      };
      let hasError = false;
      let errors = {};

      // Validate each vehicle set
      vehicleSets.forEach((vehicleSet, index) => {
        const isMakeValid = vehicleSet.make.trim() !== "";
        const isModelValid = vehicleSet.model.trim() !== "";
        const isYearValid = vehicleSet.year && vehicleSet.year.start && vehicleSet.year.end;
        const isEngineValid = vehicleSet.engine.trim() !== "";
        const isVehicleTypeValid = vehicleSet.vehicle_type && vehicleSet.vehicle_type.start && vehicleSet.vehicle_type.end;

        if (!isMakeValid) {
          errors[`make-${index}`] = "Make is required";
          hasError = true;
        }

        if (!isModelValid) {
          errors[`model-${index}`] = "Model is required";
          hasError = true;
        }

        if (!isYearValid) {
          errors[`year-${index}`] = "Year is required";
          hasError = true;
        }

        if (!isEngineValid) {
          errors[`engine-${index}`] = "Engine is required";
          hasError = true;
        }

        if (!isVehicleTypeValid) {
          errors[`vehicle_type-${index}`] = "Vehicle Type is required";
          hasError = true;
        }
      });

  
      if (hasError) {
        setFormError(errors);
        return;
      }
  
      if (actiontype === "Add") {
        dispatch(addItem(data, thumbnailImgFile));
      } else {
        dispatch(updateItem(editForm.id, data, thumbnailImgFile));
      }

      setSubmissionStatus("success");
      setTimeout(() => {
        window.location.replace("/seller_product_list");
      }, 5000);
    }
  };

  return (
    <Container className="mb-5 shadow p-3" >
      <Form onSubmit={(e) => onSubmit(e)} encType="multipart/form-data">
        <Row className="p-3 shadow m-4">
          <Col>
            <Form.Group controlId="title">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Product Name"
                name="title"
                value={title}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" onClick={addMore}>
          Add More
        </Button>

        <Row className="p-3 shadow m-4">
        {vehicleSets.map((vehicleSet, index) => (
          <Row key={index} className="p-3 shadow m-4">
            <Col>
              <Form.Group controlId={`make-${index}`}>
                <Form.Label>Make</Form.Label>
                <Form.Control
                  as="select"
                  value={vehicleSet.make}
                  onChange={(e) => handleMakeChange(e, index)}
                >
                  <option value="">Select Make</option>
                  {Object.keys(vehicleData).sort().map((make) => (
                    <option key={make} value={make}>
                      {make}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`model-${index}`}>
                <Form.Label>Model</Form.Label>
                {vehicleSet.make && (
                  <Form.Control
                    as="select"
                    value={vehicleSet.model}
                    onChange={(e) => handleModelChange(e, index)}
                  >
                    <option value="">Select Model</option>
                    {Object.keys(vehicleData[vehicleSet.make]).sort().map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </Form.Group>
            </Col>
            <Col>
            <Form.Group controlId={`year-${index}`}>
              <Form.Label>Year Range</Form.Label>
              {vehicleSet.make && vehicleSet.model && (
                <div>
                  <div>
                    <label>Start Year:</label>
                    <select
                      value={vehicleSet.year.start}
                      onChange={(e) => handleYearChange(e, index, 'start')}
                    >
                      <option value="">Select Start Year</option>
                      {Object.keys(vehicleData[vehicleSet.make][vehicleSet.model]).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div>
                    <label>End Year:</label>
                    <select
                      value={vehicleSet.year.end}
                      onChange={(e) => handleYearChange(e, index, 'end')}
                    >
                      <option value="">Select End Year</option>
                      {Object.keys(vehicleData[vehicleSet.make][vehicleSet.model]).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              )}
            </Form.Group>
            </Col>
            <Col>
            <Form.Group controlId={`engine-${index}`}>
              <Form.Label>Engine Type</Form.Label>
              {vehicleSet.make && vehicleSet.model && vehicleSet.year && (
                <Form.Control
                  as="select"
                  value={vehicleSet.engine}
                  onChange={(e) => handleEngineTypeChange(e, index)}
                >
                  <option value="">Select Engine Type</option>
                  {getEngineTypes(vehicleSet.make, vehicleSet.model, vehicleSet.year).map((engineType, i) => (
                    <option key={i} value={engineType}>
                      {engineType}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`vehicle_type-${index}`}>
                <Form.Label>Vehicle Type Range</Form.Label>
                {vehicleSet.make && vehicleSet.model && vehicleSet.year && (
                  <div>
                    <div>
                      <label>Start Vehicle Type:</label>
                      <select
                        value={vehicleSet.vehicle_type.start}
                        onChange={(e) => handleVehicleTypeChange(e, index, 'start')}
                      >
                        <option value="">Select Start Vehicle Type</option>
                        {getVehicleTypes(vehicleSet.make, vehicleSet.model, vehicleSet.year).map((vehicleType, i) => (
                          <option key={i} value={vehicleType}>
                            {vehicleType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label>End Vehicle Type:</label>
                      <select
                        value={vehicleSet.vehicle_type.end}
                        onChange={(e) => handleVehicleTypeChange(e, index, 'end')}
                      >
                        <option value="">Select End Vehicle Type</option>
                        {getVehicleTypes(vehicleSet.make, vehicleSet.model, vehicleSet.year).map((vehicleType, i) => (
                          <option key={i} value={vehicleType}>
                            {vehicleType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        ))}

        </Row>

        <Row className="p-3 shadow m-4">
          <Col>
            <Form.Group controlId="sku">
              <Form.Label>Sku</Form.Label>
              <Form.Control
                type="text"
                placeholder="Part sku no"
                name="sku"
                value={sku}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="gen_price">
              <Form.Label>B2C General Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input general price for b2c customers"
                name="gen_price"
                value={gen_price}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>
    
          <Col>
            <Form.Group controlId="weight">
              <Form.Label>Weight (kg)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Weight in (kg)"
                name="weight"
                value={weight}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>
    
          <Col>
            <Form.Group controlId="part_no">
              <Form.Label>Part Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input part no separated by (,) e.g 1234, 2345, 6789"
                name="part_no"
                value={part_no}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="p-3 shadow m-4">
        <Col>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <ReactQuill
              value={description}
              onChange={(value) => setFormData({ ...formData, description: value })}
              modules={quillModules}
              formats={quillFormats}
            />
          </Form.Group>
        </Col>
      </Row>


        <Row className="p-3 shadow m-4">
          <Col>
            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={category}
                onChange={(e) => onChange(e)}
                required
              >
                <option value="" disabled>
                Select Category
                </option>
                {categoryData.map((e, i) => (
                  <option key={i} value={e.category}>
                    {e.category}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
    
          <Col>
            <Form.Group controlId="price">
              <Form.Label>B2B Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Price"
                name="price"
                value={price}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="p-3 shadow m-4">
          <Col>
            <Form.Group controlId="thumbnail">
              <Form.Label>Thumbnail</Form.Label>
              <div>
                {thumbnail || thumbnailImgPreviewURL ? (
                  <Image
                    src={
                      thumbnailImgPreviewURL
                        ? thumbnailImgPreviewURL
                        : thumbnail
                    }
                    alt="thumbnail-preview"
                    width={200}
                    height={200}
                    rounded
                  />
                ) : (
                  <span></span>
                )}
              </div>
              <Form.Control
                type="file"
                name="thumbail"
                onChange={(e) => onImageChange(e)}
              />
            </Form.Group>
          </Col>
          
    
          <Col className="mt-4 border p-2">
             <span>For Dimension (L-W-H)</span><hr/> 
              <Form.Group controlId="length">
                <Form.Label>Length</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Length in inches"
                  name="length"
                  value={length}
                  onChange={(e) => onChange(e)}
                  required
                />
              </Form.Group>
           
              <Form.Group controlId="width">
                <Form.Label>Width</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Width in inches"
                  name="dim_width"
                  value={dim_width}
                  onChange={(e) => onChange(e)}
                  required
                />
              </Form.Group>
           
              <Form.Group controlId="height">
                <Form.Label>Height</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Height in inches"
                  name="height"
                  value={height}
                  onChange={(e) => onChange(e)}
                  required
                />
              </Form.Group>
              </Col>
        </Row>


        <Row className="p-3 shadow m-4">
          <Col>
            <Form.Group controlId="stock">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                placeholder="Stock"
                name="stock"
                value={stock}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
          </Col>

        
          <Col>
            <Form.Group controlId="condition">
              <Form.Label>Condition</Form.Label>
              <Form.Control
                as="select"
                name="condition"
                value={condition}
                onChange={(e) => onChange(e)}
                required
              >
                <option value="" disabled>
                  Select Condition
                </option>
                <option value="New">New</option>
                <option value="Renewed">Used</option>
                <option value="Used">Renew</option>
              </Form.Control>
            </Form.Group>
          </Col>
       
          <Col className="shadow m-4" >
            <Form.Group controlId="rating">
              <Form.Label>Rating</Form.Label>
              <Rating className="p-2" onClick={handleRatig} ratingValue={rating} size={20} />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button type="submit">Submit</Button>

        {Object.keys(formError).map((field) => (
          <Alert key={field} variant="danger">
            {formError[field]}
          </Alert>
        ))}
        {submissionStatus === "success" && (
          <Alert variant="success">
            Form submitted successfully! Redirecting to the product listing page
            now in 10 seconds...
          </Alert>
        )}
      </Form>
    </Container>
  );
};

export default ProductForm;
