import { Card, Container, Row } from 'react-bootstrap';


const ProductLayout = ({ children, category }) => {
  return (
    <Card className="p-4 mt-4 shadow">
      <div>
        <h1>{category}</h1> <hr/>
      </div>
      <Container className="d-flex flex-wrap">
        {children}
      </Container>
    </Card>
  );
};

export default ProductLayout;
