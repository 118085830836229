import React, { useState } from 'react';
import './ChatbotModal.css'; // Import the CSS for styling

const ChatbotModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the modal
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Chatbot Button */}
      <button className="chatbot-btn" onClick={toggleModal}>
        Chat with our AI !
      </button>
      {/* Modal for Chatbot */}
      {isOpen && (
        <div className="modals" onClick={toggleModal}>
          <div className="modals-content" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={toggleModal}>&times;</span>
            <iframe
              src="https://ai.mazesai.com/chat/embed?id=SXC4f"
              style={{ width: '100%', height: '90%', border: 'none' }}
              title="Chatbot"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatbotModal;
