import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BuyingBox from '../../components/BuyingBox/BuyingBox';
import DescriptionBoxProduct from '../../components/DescriptionBoxProduct/DescriptionBoxProduct';
import Navbar from '../../components/Navbar/Navbar';
import PhotoboxProduct from '../../components/PhotoboxProduct/PhotoboxProduct';
import Footer from '../../components/Footer/Footer';

const DetailProduct = () => {
  const location = useLocation();
  const { item } = location.state;

  return (
    <div>
         
      <Navbar />
      <Container  className="m-2">
        <h1>DetailProduct</h1>
        <Row>
          <Col >
            <PhotoboxProduct id={item?.id} />
          </Col>
          <Col >
            <DescriptionBoxProduct item={item} />
          </Col>
          <Col lg={2} >
            <BuyingBox productId={item?.id} stock={item?.stock} />
          </Col>
        </Row>
      </Container>
     
      <Footer />
    </div>
  );
};

export default DetailProduct;
