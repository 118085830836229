// optionReducers.js
import { 
    FETCH_MAKE_OPTIONS, 
    FETCH_MODEL_OPTIONS, 
    FETCH_YEAR_OPTIONS, 
    FETCH_ENGINE_OPTIONS, 
    FETCH_VEHICLE_TYPE_OPTIONS 
} from '../types/optionTypes';

const initialState = {
  makeOptions: [],
  modelOptions: [],
  yearOptions: [],
  engineOptions: [],
  vehicleTypeOptions: [],
};

export const optionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAKE_OPTIONS:
      return {
        ...state,
        makeOptions: action.payload,
      };
    case FETCH_MODEL_OPTIONS:
      return {
        ...state,
        modelOptions: action.payload,
      };
    case FETCH_YEAR_OPTIONS:
      return {
        ...state,
        yearOptions: action.payload,
      };
    case FETCH_ENGINE_OPTIONS:
      return {
        ...state,
        engineOptions: action.payload,
      };
    case FETCH_VEHICLE_TYPE_OPTIONS:
      return {
        ...state,
        vehicleTypeOptions: action.payload,
      };
    default:
      return state;
  }
};

// export default optionReducer;
