import React from 'react';
import { Image, Container, Col, Row } from 'react-bootstrap';

function BannerB2c() {
  const imageStyle = {
    borderRadius: '100px', // Adjust the value as needed for your desired border radius
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    height: '200px',
    padding: '10px',
    objectFit: 'cover', 
    width: '100%',
  };

  return (
    <Container fluid className="position-relative p-0">
      <Image
        style={{ width: '100%', height: '500px', objectFit: 'cover' }}
        src="https://wallpaperaccess.com/full/2056609.jpg"
        fluid
      />
      <div
        className="overlay-text w-50 text-left text-white position-absolute"
        style={{
          padding: '20px',
          backgroundColor: 'rgba(128, 129, 150, 0.4)',
          marginTop: '-350px',
          marginLeft: '40px',
          marginBottom: '300px',
        }}
      >
        <div>
          <h1>Welcome to Kamsiparts Automotive</h1>
          <p>B2B2C digital ecosystem for safe, efficient Auto Parts transactions! </p>
        </div>
      </div>

      <Row className="justify-content-center p-4">
        <Col xs={6} sm={6} md={4} lg={2}>
          <Image
            src="https://prtautoparts.com/wp-content/uploads/2020/04/PRT-34.jpg"
            className="img-fluid shadow"
            style={imageStyle}
          />
        </Col>
        <Col xs={6} sm={6} md={4} lg={2}>
          <Image
            src="https://www.kamsiparts.com/wp-content/uploads/2022/11/unveil-november-min-scaled.jpg"
            className="img-fluid shadow"
            style={imageStyle}
          />
        </Col>
        <Col xs={6} sm={6} md={4} lg={2}>
          <Image
            src="https://st2.depositphotos.com/1005404/7746/i/450/depositphotos_77466908-stock-photo-car-parts.jpg"
            className="img-fluid shadow"
            style={imageStyle}
          />
        </Col>
        <Col xs={6} sm={6} md={4} lg={2}>
          <Image
            src="https://www.nairaland.com/attachments/17119064_productsq1_jpeg142de9e68f6513eaab1ebea22caffec6"
            className="img-fluid shadow"
            style={imageStyle}
          />
        </Col>

      </Row>
    </Container>
  );
}

export default BannerB2c;
