import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ProgressBar, Alert } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';

function UploadData() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Get CSRF token from the cookie and set it as a default header for all Axios requests
    const csrfToken = Cookies.get('csrftoken');
    axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
  }, []);

  // Function to handle file upload
  const handleFileUpload = () => {
    setError(null); // Clear previous errors
    setSuccessMessage(''); // Clear previous success message
    const formData = new FormData();
    formData.append('file', selectedFile);

    axios
      .post(`${process.env.REACT_APP_API_URL}api/upload/`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentage);
        },
      })
      .then((response) => {
        console.log(response.data.message);
        setSuccessMessage(response.data.message); // Set success message
      })
      .catch((error) => {
        console.error(error);
        setError('An error occurred during the file upload.');
      });
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6} xs={12}>
          <div className="p-4 shadow rounded bg-white">
            <h3 className="text-center mb-4">Upload Data</h3>
            <input
              type="file"
              accept=".xlsx, .sql"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className="form-control mb-3"
            />
            <Button
              variant="primary"
              onClick={handleFileUpload}
              disabled={!selectedFile}
              block
            >
              Upload File
            </Button>
            {uploadProgress > 0 && uploadProgress < 100 && (
              <ProgressBar className="mt-3" now={uploadProgress} label={`${uploadProgress}%`} />
            )}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default UploadData;
