import Navbar from "../../components/Navbar/Navbar";
// import Banner from "../../components/Banner/Banner";
import BannerB2c from "../Homepage/BannerB2c/bannerb2c";
import BulkPurchaseSection from "../Homepage/BulkPurchaseSection/SectionBulkpurchase";
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import ProductBanner from "../../components/ProductBanner/ProductBanner";
import LogoTransition from "../../components/LogoSlider/LogoSlider";
import TestimonySection from "../Homepage/Testimonial/Testimonial";
import ChatbotModal from "../../components/ChatBotAi/ChatBotAi";
import './home.css';
import Footer from "../../components/Footer/Footer";


const Home = ()=>{
    return (
        <div className="HomeContainer">
            <Navbar />
            {/* <Banner /> */}
            <BannerB2c />
            <ProductSlider/>
            <BulkPurchaseSection/>
            <ProductBanner/>
            <LogoTransition/>
            <TestimonySection/>
            <ChatbotModal/>
            <Footer/>
        </div>
    )
}

export default Home;