import { useDispatch, useSelector } from "react-redux";
// import styles from "./styles.module.css";
import { getItems } from "../../actions/itemActions";
import { useEffect } from "react";
import ProductCard from "./ProductCard/ProductCard";
import CategoryCard from "./CategoryCard/CategoryCard";
import { Row, Col, Container } from "react-bootstrap";

const category = [
  {
    id: 1,
    category: "PRT Shocks",
  },
  {
    id: 2,
    category: "Brakes, Suspension & Steering",
  },
  {
    id: 3,
    category: "Batteries and Chargers",
  },
  {
    id: 4,
    category: "Sensors",
  },
  {
    id: 5,
    category: "Engine & Drivetrain",
  },
  {
    id: 6,
    category: "Filters",
  },
  {
    id: 7,
    category: "Auto Body Parts & Mirrors",
  },
];

const ProductBanner = () => {
  const { items } = useSelector((state) => state.itemReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItems("api/product/"));
  }, [dispatch]);

  return (
    <Container fluid className="mt-5" >
      <div>
        <h2>New Products</h2>
        <Row>
          {items.slice(0, 6).map((item, index) => (
            <Col  key={index}>
              <ProductCard item={item} />
            </Col>
          ))}
        </Row>
        <div className="mt-5">
          <h2>Shop By Category</h2>
          <Row>
            {category.slice(0, 7).map((categoryItem, index) => (
              <Col key={index}>
                <CategoryCard
                  category={categoryItem.category}
                  link={categoryItem.id}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Container>
  );
};
export default ProductBanner;
