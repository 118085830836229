import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../../actions/authActions";
import Navbar from "../../components/Navbar/Navbar";

import {Container, Form, Button} from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
// import "./Signup.css";

const Signup = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    confirm_password: "",
    role_id: "b2crole", 
  });

  const { username, email, phone, address, password, confirm_password, role_id } =
    formData;

  const roleMappings = {
    fleetrole: 2,
    companyrole: 3,
    thirdprole: 4,
    workshoprole: 5,
    b2crole: 6,
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onClick = (e) => {
    e.preventDefault();
    dispatch(
      register({
        username,
        email,
        phone,
        address,
        password,
        role_id: roleMappings[formData.role_id],
      })
    );
  };
  
  useEffect(() => {
    if (isAuthenticated) {
      if (formData.role_id === 'b2crole') {
        // Redirect to  dashboard
        console.log(formData)
        window.location.replace('/login');
      } else if (formData.role_id === 'fleetrole') {
        // Redirect to fleetrole landing page
        window.location.replace('/login');
      } else if (formData.role_id === 'thirdprole') {
        // Redirect to thirdpartyrole landing page
        window.location.replace('/login');
      } else if (formData.role_id === 'workshoprole') {
        // Redirect to workshoprole landing page
        window.location.replace('/login');
      } else if (formData.role_id === 'companyrole') {
        // Redirect to companyrole dashboard
        console.log(formData)
        window.location.replace('/login');
      }
    }
  }, [dispatch, isAuthenticated, formData.role_id, formData]);

  return (
    <div>
      <Navbar />
    <Container fluid className="d-flex justify-content-center">
        <Form className="form-container p-4" onSubmit={(e) => onClick(e)}>
          <h3>Signup</h3>
          <Form.Group className="form-column">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={username}
              placeholder="Username"
              onChange={(e) => onChange(e)}
              required
            />
          </Form.Group>
          <Form.Group className="form-column">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              onChange={(e) => onChange(e)}
              required
            />
          </Form.Group>
          <Form.Group className="form-column">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={address}
              placeholder="Address"
              onChange={(e) => onChange(e)}
              required
            />
          </Form.Group>
          <Form.Group className="form-column">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="number"
              name="phone"
              value={phone}
              placeholder="Phone"
              onChange={(e) => onChange(e)}
              required
            />
          </Form.Group>
          <Form.Group className="form-column">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={password}
              placeholder="Password"
              onChange={(e) => onChange(e)}
              required
            />
          </Form.Group>
          <Form.Group className="form-column">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirm_password"
              value={confirm_password}
              placeholder="Confirm Password"
              onChange={(e) => onChange(e)}
              required
            />
          </Form.Group>
          <Form.Group className="form-column">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role_id"
              value={role_id}
              onChange={(e) => onChange(e)}
              required
            >
              <option value="b2c" disabled>Select role</option>
              <option value="fleetrole">Distributors/Retailers</option>
              <option value="companyrole">Fleets/Corporate Clients</option>
              <option value="thirdprole" >3rd Party Suppliers</option>
              <option value="workshoprole" >Workshops/Garages</option>
              <option value="b2crole" >B2C Customers</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit" className="login-button">
            Signup
          </Button>
          <div className="mt-3">
            Do you have an account?
            <Link to={"/login"}>
              <span> Login here</span>
            </Link>
          </div>
        </Form>
      </Container>
   
      <Footer />
    </div>
  );
};

export default Signup;