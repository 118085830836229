import React from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';

const VCard = () => {
  return (
    <Container className="text-center d-flex align-items-center justify-content-center" >
      <Card className="m-2" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Row className="justify-content-center p-3 m-1" style={{ backgroundColor: '#B9DBFF' }}>
          <Col md={10} className="text-center">
            <Row className="justify-content-center">
              <Col md={6} className='d-flex justify-content-center align-items-center p-2'>
                <Card.Img variant="top" src="https://www.kamsiparts.com/wp-content/uploads/2021/12/Kamsi_Quality_Logo_plainbg.png" style={{ objectFit: 'contain', width: '60%' }} alt="Logo 1" />
                <Card.Img variant="top" src="https://www.tchikamalorglobal.com/wp-content/uploads/2019/02/logo.png" style={{ objectFit: 'contain', width: '50%' }} alt="Logo 2" className="pl-4" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col >
            <Card.Body>
              <Card.Title className="text-dark">Amaeze Ndukwe C.</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Founder</Card.Subtitle>
              <Card.Text>
                Creative innovator, strong negotiator, and project manager with a transparent and ethical mindset.
                <hr/><p><a href="https://www.linkedin.com/in/amaeze-ndukwe-37625310"> Click to view my Linkedin profile</a></p>
                <hr/> </Card.Text>
              <Row className="justify-content-center">
                <Col className="m-2 p-4" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} md={5}>
                  <h6>NG Business Address:</h6>
                  <p>Operations: Zone D10A, 72. <br/> Sales: Zone D8, 85 ASPMDA Shopping Complex, Int'l Trade Fair Ojo Lagos</p>
                  <h6>USA Business Address:</h6>
                  <p>Kamsiparts Automotive INC 8, The Green, Suite R Dover, DE 19901. USA</p>
                  <p><a href="tel:+13024691130">Tel: +1 302 469 1130</a></p>
                  
                  <h6>Email 1:</h6>
                  <p><a href="mailto:andukwe@tchikamalorglobal.com">andukwe@tchikamalorglobal.com</a></p>
                  <h6>Phone 1:</h6>
                  <p><a href="tel:+2348033356957">+2348033356957</a></p>
                  <Button variant="primary" onClick={() => window.open('https://www.kamsiparts.com', '_blank')}>
                    Visit Kamsiparts Website
                  </Button>
                </Col>
                <Col className="m-2 p-4" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} md={5}>
                  <h6>Email 2:</h6>
                  <p><a href="mailto:namaeze@gmail.com">namaeze@gmail.com</a></p>
                  <h6>Phone 2:</h6>
                  <p><a href="tel:+2348098370120">+2348027832477</a></p>
                  <Button variant="primary" onClick={() => window.open('https://www.tchikamalorglobal.com/', '_blank')}>
                    Visit Tchikamalor Global Website
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default VCard;
