import React from 'react';
import './LogoTransition.css'; // Create this CSS file for animations

const LogoTransition = () => {
  const partnerLogos = [
    'https://www.kambi3pl.com/public/uploads/settings/202407170809057880.jpg',
    'https://www.mazesai.com/images/735031721044992655mazeai%20logo.jpg',
    'https://images.africanfinancials.com/ng-rtbris-logo.png',
    'https://www.adexen.com/upload/company-logos/15796429361582875688.jpg',
    'https://championbreweries.com/wp-content/uploads/2023/05/logo.png',
    'https://pbs.twimg.com/profile_images/1542435311325790208/5DqBKfR3_400x400.jpg',
    'https://1000logos.net/wp-content/uploads/2021/04/AliExpress-logo.png',
    'https://upload.wikimedia.org/wikipedia/en/f/f3/Nampak_logo.png',
    'https://pbs.twimg.com/profile_images/899598400706031616/XoOmoPHR_400x400.jpg',
    'https://importyourcar.ng/wp-content/themes/Import-Express-v3/img/logo/trans.png', 
    'https://sp.mycarviva.com/img/logo-new.png',
    'https://carvity.com/wp-content/uploads/2022/09/CARvity2.png',
    'https://automedicsnigeria.com/wp-content/uploads/2021/12/Main-Logo-2-1.png',
    'https://zandaux.com/image/images/6432657e5bb80.webp?p=logo',
    'https://fixit45.com/images/logo.png',
  ];

  const clientLogos = [
    'https://images.canadawheels.ca/images/Brands/1630594637tecmate-logo.png',
    'https://upload.wikimedia.org/wikipedia/commons/c/c3/Bosch_logo.png',
    'https://www.saka.com.lb/wp-content/uploads/2022/05/autel-logo.png', 
    'https://w7.pngwing.com/pngs/428/1008/png-transparent-dhl-hd-logo.png',
    'https://s19538.pcdn.co/wp-content/uploads/2020/06/PRT-logo.jpg',
    'https://www.oximo.pl/wp-content/themes/oximo/assets/images/OXIMO_simply-works.svg', 
    'https://sct-catalogue.de/image/design/logo-sct.png',
    'https://www.mannol.de/Theme/cpartner/assets/img/icons/logo.png',
    'https://apdcarparts.s3.eu-west-2.amazonaws.com/1/5/s/VIKA-DPA-logo-430x70.jpg',
    'https://indramotorspares.com/wp-content/uploads/555.jpg',
    'https://1000logos.net/wp-content/uploads/2018/02/logo-toyota.png',
    'https://1000logos.net/wp-content/uploads/2018/09/acura-symbol.jpg',
    'https://1000logos.net/wp-content/uploads/2018/02/BMW-Logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2018/02/Ford-Logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2020/03/nissan-logo-500x334.png',
    'https://1000logos.net/wp-content/uploads/2021/02/Peugeot-logo-500x333.png',
    'https://1000logos.net/wp-content/uploads/2019/12/Mazda-Logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2019/12/Chevrolet-logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2018/03/Honda-Logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2020/04/Infiniti-Logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2020/02/Land-Rover-Logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2020/02/Lexus-L%D0%BEgo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2018/04/Jeep-logo-500x281.png',
    'https://1000logos.net/wp-content/uploads/2020/04/Pontiac-Logo-500x281.png'
  ];

  return (
    <div className="logo-transition-container mt-5 ">
      <h2>Corporate Partners</h2>
      <div className="logo-transition-wrapper partners">
        {partnerLogos.map((logo, index) => (
          <img key={index} src={logo} alt={`Partner Logo ${index}`} className="transition-logo" />
        ))}
      </div>
      <h2 className='our-client'>Our Brands</h2>
      <div className="logo-transition-wrapper clients">
        {clientLogos.map((logo, index) => (
          <img key={index} src={logo} alt={`Client Logo ${index}`} className="transition-logo" />
        ))}
        
      </div>
    </div>
    
  );
};

export default LogoTransition;
