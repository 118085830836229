import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"; // Import useHistory for redirection
import { getItemsCart } from "../../actions/cartActions";
import { getUser, getUserCode } from '../../actions/companyUserActions';
import Navbar from "../../components/Navbar/Navbar";
import { Container, Row, Col, Card, Alert, Button } from 'react-bootstrap'; // Import Alert and Button
import { PaystackButton } from 'react-paystack'
import Footer from "../../components/Footer/Footer";


const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const { cartId, totalPrices } = location.state;
  const { cartItem } = useSelector((state) => state.cartReducer);
  const { token } = useSelector((state) => state.authReducer);
  const { user, userCode } = useSelector((state) => state.companyUserReducer);
  const dispatch = useDispatch();

  const [quantityUpdates] = useState({});

  // setting up paystack configuration
  const publicKey = 'pk_live_e4051c3d2c8d66af054c40a4a2a4b3160cf7172b'; // Replace with your Paystack public key
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");

  // New state for wallet payment success alert
  const [walletPaymentSuccess, setWalletPaymentSuccess] = useState(false);

  const handlePaymentSuccess = (response) => {
    setPaymentSuccess(true);
    // Perform any necessary actions on payment success
  };
  
  const handlePaymentError = (error) => {
    setPaymentError(true);
    // Perform any necessary actions on payment error
  };

  const handleWalletPayment = () => {
    const formData = new FormData();
    formData.append('total_price', totalPrices);
    formData.append('user_id', user.id);
    formData.append('username', user.username);
    formData.append('unique_code', userCode.unique_code);
    formData.append('products_purchased', JSON.stringify(cartItem.map(item => ({ title: item.product_details.title, quantity: item.quantity }))));
  
    return fetch(`${process.env.REACT_APP_API_URL}api/wallet-checkout/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Error processing wallet checkout: ${response.statusText}`);
      }
    })
    .then((responseData) => {
      console.log('Wallet checkout successful:', responseData);
      setWalletPaymentSuccess(true);
      // Redirect to the dashboard after successful payment
      setTimeout(() => {
        navigate('/company-dashboard');
      }, 5000);
    })
    .catch((error) => {
      console.error('Error processing wallet checkout:', error);
      setPaymentError(true);
    });
  };
  

  const handleWhatsAppPayment = () => {
    // Construct WhatsApp message with transaction details
    let message = `Hello, I would like to make a payment for my order.\n\n`;
    message += `From: ${user ? user.username : 'Loading...'}\n`; // Include user's name
    message += `Unique Code: ${userCode ? userCode.unique_code : 'Loading...'}\n\n`; // Include user's unique code
  
    // Include product details and quantity for each item in the cart
    cartItem.forEach((item, index) => {
      const { product_details, quantity } = item;
      const productName = product_details?.title || "Product";
      const productQuantity = quantity || 0;
      message += `${index + 1}. ${productName} - Quantity: ${productQuantity}\n`;
    });
  
    // Add total price
    message += `\nTotal Price: ₦${totalPrices}`;
  
    // Encode message for URL
    message = encodeURIComponent(message);
  
    // Add your business WhatsApp number here
    const phoneNumber = "+2349036301891";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
  
    // Open WhatsApp chat
    window.open(whatsappUrl, '_blank');
  };
  
  

  const calculateDiscount = (quantity) => {
    let discount = 0;
    if (quantity >= 0 && quantity < 5) {
      discount = 0.03; // 3% discount
    } else if (quantity >= 5 && quantity < 10) {
      discount = 0.05; // 5% discount
    } else if (quantity >= 10) {
      discount = 0.07; // 7% discount
    }
    return discount;
  };

  useEffect(() => {
    let tokenParse = [];
    if(token !== null && token !== 'undefined'){
      // Retrieve user details from the token
      tokenParse = JSON.parse(token);
      const { username, email, phone, address } = tokenParse || {};
       // console.log(phone)
      dispatch(getItemsCart(cartId))
      dispatch(getUser(tokenParse.id));
      dispatch(getUserCode(tokenParse.id));

      // Update the state variables
      setUserEmail(email || "");
      setUserPhone(phone || "");
      setUserName(username || "");
      setUserAddress(address || "");
      
    }
  }, [dispatch, token, cartId])


  return (
    <>
      <Navbar />
      <Container className="shadow mt-5 p-4">
        <h1 className="mt-3">Your Order Details</h1>
        <h5>My name is: <b>{user ? user.username : 'Loading...'} </b>, these are my orders below
        with a Unique Code: <b>{userCode ? userCode.unique_code : 'Loading...'}</b></h5>
        <Row>
          {cartItem.map((item, index) => {
            const { id, product_details, quantity } = item;
            const updatedQuantity = quantityUpdates[id] !== undefined ? quantityUpdates[id] : quantity;
            const discount = calculateDiscount(updatedQuantity);
            const discountedPrice = product_details.price * (1 - discount);

            return (
              <Col key={index} sm={6} md={4} lg={4} >
                <Card className="shadow mt-4">
                  <Card.Body>
                    <Card.Title>{product_details?.title}</Card.Title>
                    <Card.Text>
                      <div><strong>Price:</strong> ₦{product_details.price.toFixed(2)} </div>
                      <div><strong>Discount:</strong> {discount * 100}%</div>
                      <div> <strong>Total Price with Discount:</strong> ₦{discountedPrice.toFixed(2)}</div>
                      <div> <strong>Quantity:</strong> {updatedQuantity}</div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className="mt-3 pb-4 pt-3 d-flex">
        <h3>Total Price: ₦{totalPrices}</h3>
        {paymentSuccess && <p>Payment Successful</p>}
        {paymentError && <p>Payment Error</p>}
        <div className="text-center">
        <PaystackButton
          text="Pay Via Our Online Gateway" // Button text
          className="btn btn-primary mt-2 ml-3" // Button class
          callback={handlePaymentSuccess} // Callback function for successful payment
          close={handlePaymentError} // Callback function for payment error or close
          disabled={paymentSuccess || paymentError} // Disable the button if payment is successful or an error occurred
          embed={false} // Display the payment form as an embedded overlay or redirect to the Paystack page
          reference={`paystack-${Date.now()}`} // Unique payment reference
          email={userEmail}
          metadata={{
            phone: userPhone,
            username: userName,
            address: userAddress}}
          amount={totalPrices * 100} // Payment amount in kobo (multiply by 100 to convert to kobo)
          publicKey={publicKey} // Paystack public key
          tag="button" // HTML tag to render as
        />

        {/* Pay with Wallet Button */}
        <button className="btn btn-primary ml-2 mt-2" onClick={handleWalletPayment} disabled={userCode && userCode.balance === 0}>
            Pay Via Wallet Balance: {userCode && userCode.balance > 0 && ` ₦${userCode.balance}`}
          </button>
        </div>

        {/* Pay via WhatsApp Button */}
        <button className="btn btn-primary ml-2 mt-2" onClick={handleWhatsAppPayment}>
            Pay Via WhatsApp
          </button>

      </Container>

      {/* Wallet Payment Success Alert */}
      {walletPaymentSuccess && (
        <Alert variant="success" className="text-center">
          Payment via wallet was successful! Redirecting to the dashboard...
        </Alert>
      )}
      <Footer />
    </>
  );
};
export default Checkout;
