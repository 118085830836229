import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getItems } from '../../actions/itemActions';
import { Card, Button } from 'react-bootstrap';

const ProductSlider = () => {
  const dispatch = useDispatch();
  

  // Fetch user token from localStorage
  const tokenString = localStorage.getItem('token');
  const token = tokenString ? JSON.parse(tokenString) : null;
  // Check if the user is logged in and has a role
  const tokenReady = token !== null && token !== undefined && token.role && token.role.id !== undefined;

  const { items } = useSelector((state) => state.itemReducer);
  
  useEffect(() => {
    dispatch(getItems('api/product/'));
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className='pt-5'>
      <div>
        <h2 style={{ color: 'white', backgroundColor: 'rgb(35, 35, 199)', padding:'10px'}}>Featured Products</h2>
        <Slider {...settings}>
          {items.map((item) => (
            <div key={item.id} className='p-2' >
              <Link
                to="/detail_product"
                style={{ textDecoration: 'none',}}
                state={{ item: item }}
              >
                 <Card style={{ width: '100%', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: 'box-shadow 1s, transform 1s' }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0 8px 16px 0 rgba(0,0,0,0.2)';
                    e.currentTarget.style.transform = 'translateY(-4px)';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '0 4px 8px 0 rgba(0,0,0,0.2)';
                    e.currentTarget.style.transform = 'translateY(0)';
                  }}
                >
                  <Card.Img variant="top" src={item.thumbnail} style={{ height: '150px', objectFit: 'cover' }} />
                  <Card.Body>
                    <Card.Title style={{ height: '20px' }} className="overflow-hidden">{item.title?.length > 25 ? item.title?.substring(0,25)+'. . .':item.title}</Card.Title>
                    <Card.Text style={{ height: '100px', paddingTop: '20px' }} className="overflow-hidden" dangerouslySetInnerHTML={{ __html: item.description?.length > 100 ? item.description?.substring(0, 100) + '. . .' : item.description }} />
                    {/* Check user role and display price accordingly */}
                    { tokenReady && token.role && (token.role.id === 1 || token.role.id === 4 || token.role.id === 2 || token.role.id === 3) ? (
                      <Card.Text>₦{item.price}</Card.Text>
                    ) : (
                      <Card.Text>₦{item.gen_price}</Card.Text>
                    )}
                   
                    <Button variant="primary" >Shop Now</Button>
                  </Card.Body>
                </Card>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSlider;
