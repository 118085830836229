import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import store from './store';
import Home from './pages/Home/Home';
import Homepage from './pages/Homepage/b2chome'
import AboutUs from './pages/AboutUs/AboutUs';
import CartView from './pages/CartView/CartView';
import Checkout from './pages/CartView/Checkout';
import DetailProduct from './pages/DetailProduct/DetailProduct';
import Login from './pages/Login/Login';
import LoginCopy from './pages/Login/Login copy';
import Product from './pages/Product/Product';
import Dashboard from './pages/Dashboard/Dashboard';
import CompanyRoleDashboard from './pages/Dashboard/CompanyRoleDashboard';
import SellerProductList from './pages/SellerProductList/SellerProductList';
import Signup from './pages/Signup/Signup';
import SignupAdmin from './pages/Signup/SignupAdmin';
import PreviewImages from './pages/PreviewImages/PreviewImages';
import UploadData from './pages/PreviewImages/UploadApiData';
import VCard from './pages/Vcard/vcard';
import VehicleDropdown from './pages/Vcard/VehicleDataModal';
import 'react-quill/dist/quill.snow.css'; 

function App() {
  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', disableContextMenu);
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);
  
  const tokenString = localStorage.getItem('token');
  // console.log('Token:', tokenString);

  const token = tokenString ? JSON.parse(tokenString) : null;
  // console.log('Parsed Token:', token);

  const tokenReady = token !== null && token !== undefined && token.role && token.role.id !== undefined;
  console.log('Token Ready:', tokenReady);
  

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/product" element={<Product />} />
          <Route path="/vcard" element={<VCard />} />
          <Route path="/vehicledata" element={<VehicleDropdown />} />
          <Route path="/seller_product_list" element={<SellerProductList />} />
          <Route path="/previewImages" element={<PreviewImages />} />
          <Route path="/upload_data" element={<UploadData />} />

          {/* Dashboard Route */}
          <Route
            path="/dashboard"
            element={
              tokenReady && token.role.id === 1 ? (
                <>
                  <Dashboard />
                  <Routes>
                    <Route path="/seller_product_list" element={<SellerProductList />} />
                    <Route path="/previewImages" element={<PreviewImages />} />
                    <Route path="/upload_data" element={<UploadData />} />
                  </Routes>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

  
          {/* Company Dashboard Route */}
          <Route
            path="/company-dashboard"
            element={
              tokenReady && (token.role.id === 2 || token.role.id === 3 || token.role.id === 4 || token.role.id === 5 || token.role.id === 6) ? (
                <CompanyRoleDashboard />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* Restricted Routes for Role 2 and Role 3 */}
          {tokenReady && (token.role.id === 2 || token.role.id === 3 || token.role.id === 4 || token.role.id === 5 || token.role.id === 6) ? (
            <>
              <Route path="/previewImages" element={<Navigate to="/" />} />
              <Route path="/upload_data" element={<Navigate to="/" />} />
              <Route path="/seller_product_list" element={<Navigate to="/" />} />
              <Route path="/dashboard" element={<Navigate to="/" />} />
            </>
          ) : null}

          <Route path="/cartView" element={tokenReady ? <CartView /> : <Navigate to="/login" />} />
          <Route path="/checkout" element={tokenReady ? <Checkout /> : <Navigate to="/login" />} />
          <Route path="/detail_product" element={<DetailProduct />} />
          <Route path="/adminlogin" element={tokenReady ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/adminsignup" element={tokenReady ? <Navigate to="/dashboard" /> : <Signup />} />
          <Route path="/login" element={tokenReady ? <Navigate to="/" /> : <LoginCopy />} />
          <Route path="/signup" element={tokenReady ? <Navigate to="/" /> : <Signup />} />
          <Route path="/signup-encrypted-admin" element={tokenReady ? <Navigate to="/" /> : <SignupAdmin/>} />
          
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
