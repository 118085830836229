import Navbar from "../../components/Navbar/Navbar";
import BannerB2c from "./BannerB2c/bannerb2c";
import ProductHomeSlider from "./ProductSliderB2c/productsliderb2c";
import BulkPurchaseSection from "./BulkPurchaseSection/SectionBulkpurchase";
import LogoTransition from "../../components/LogoSlider/LogoSlider";
import TestimonySection from "./Testimonial/Testimonial";
import Footer from "../../components/Footer/Footer";


const Homepage = ()=>{
    return (
        <div>
            <Navbar />
            <BannerB2c/>
            <ProductHomeSlider/>
            <BulkPurchaseSection/>
            <LogoTransition/>
            <TestimonySection/>
            <Footer/>
        </div>
    )
}

export default Homepage;