export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const FETCH_WALLET_DATA = 'FETCH_WALLET_DATA'
export const FETCH_WALLET_ERROR = 'FETCH_WALLET_ERROR'
export const FETCH_TRANSACTION_HISTORY = 'FETCH_TRANSACTION_HISTORY'
export const FETCH_TRANSACTION_HISTORY_ERROR = 'FETCH_TRANSACTION_HISTORY_ERROR'
export const FETCH_PURCHASE_REQUESTS = 'FETCH_PURCHASE_REQUESTS'
export const FETCH_PURCHASE_REQUESTS_ERROR = 'FETCH_PURCHASE_REQUESTS_ERROR'
