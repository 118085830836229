import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faGoogle, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import {faHome} from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <footer className='bg-light text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='#' className='me-4 text-reset'>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href='#' className='me-4 text-reset'>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href='#' className='me-4 text-reset'>
            <FontAwesomeIcon icon={faGoogle} />
          </a>
          <a href='#' className='me-4 text-reset'>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href='#' className='me-4 text-reset'>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href='#' className='me-4 text-reset'>
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
      </section>

      <section>
        <Container className='text-center text-md-start mt-5'>
          <Row className='mt-3'>
            <Col md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <img
                  src="https://i0.wp.com/www.kamsiparts.com/wp-content/uploads/2021/12/Kamsi_Quality_Logo_plainbg.png?w=738&ssl=1"
                  alt="logo"
                  style={{ width: '100%', maxWidth: '150px' }}
                />
              </h6>
              <p>
                Kamsiparts: B2B2C digital ecosystem for safe, efficient Auto Parts transactions!
              </p>
            </Col>

            <Col md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='https://www.kamsiparts.com/workshop/' className='text-reset'>
                  Workshops/Garages
                </a>
              </p>
              <p>
                <a href='https://www.kamsiparts.com/fleet/' className='text-reset'>
                  Fleets/Corporate Clients
                </a>
              </p>
              <p>
                <a href='https://www.kamsiparts.com/3rd-party-suppliers/' className='text-reset'>
                  3rd Party Suppliers
                </a>
              </p>
              <p>
                <a href='https://www.kamsiparts.com/distributors-retailers/' className='text-reset'>
                  Distributors/Retailers
                </a>
              </p>
              
            </Col>

            <Col md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='https://www.kamsiparts.com/refund_returns/' className='text-reset'>
                  Refund & Return Policy
                </a>
              </p>
              <p>
                <a href='https://www.kamsiparts.com/terms-of-service/' className='text-reset'>
                  Terms of Service
                </a>
              </p>
              <p>
                <a href='https://www.kamsiparts.com/contact-us-2/' className='text-reset'>
                  Contact Us
                </a>
              </p>
              <h6 className='text-uppercase fw-bold mb-2'>Working Hours</h6>
              <p>
                <FontAwesomeIcon icon={faHome} className="me-2" />
                Mon-Sat 8:00am - 5:00pm
              </p>
              
            </Col>

            <Col md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-2'>Phone</h6>
              <p>
                <p><a href="tel:+2349098370120">+2349098370120</a></p>
                <p><a href="tel:+13024691130">+1 3024691130</a></p>
                <p><a href="tel:+13024691140">Fax: +1 3024691140</a></p>
                
              </p>
              <h6 className='text-uppercase fw-bold mb-2'>Email</h6>
              <p>
                <p><a href="mailto:info@kamsiparts.com">info@kamsiparts.com</a></p>
              </p>
              <h6 className='text-uppercase fw-bold mb-2'>Contact</h6>
              <p>
                <FontAwesomeIcon icon={faHome} className="me-2" />
                NG Business Address: <br /> 
                Operations: Zone D10A, 72.  <br /> 
                Sales: Zone D8, 85 ASPMDA Shopping Complex, <br /> 
                Int'l Trade Fair Ojo, Lagos
              </p>
              <p>
                <FontAwesomeIcon icon={faHome} className="me-2" />
                USA Business Address: <br /> 
                Kamsiparts Automotive INC <br /> 
                74 E Glenwood Ave, #5396 <br />
                Smyma, DE 19977. <br />
                Delaware USA
              </p>
             
             

             
            </Col>
          </Row>
        </Container>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='https://kamsiparts.com/'>
          Kamsiparts.com
        </a>
      </div>
    </footer>
  );
}
