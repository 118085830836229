import{
    USER_LOADING,
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL
} from '../types/companyAuthTypes'

import axios from "axios";


export const loadUser = ()=> async (dispatch)=>{
    dispatch({type: USER_LOADING });
    const config ={
        Headers:{
            'Content-Type' : 'application/json'
        }
    }

    await axios
    .get(`${process.env.REACT_APP_API_URL}api/company-role/`, config)
    .then((res) => {
      const user = res.data;
      dispatch({
        type: USER_LOADED,
        payload: {
          ...user,
          role: user.role, // Set the role property based on user's role
        },
      });
    });
}



export const register = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    // Register the user and obtain the id from the registration response
    const registrationResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}api/user/`,
      data,
      config
    );

    const userId = registrationResponse.data?.id;

    // Use the obtained userId to create a cart
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/cart/`,
      {
        userId,
        quantity: 0,
      },
      config
    );

    // Dispatch the userId to your Redux store
    dispatch({
      type: REGISTER_SUCCESS,
      payload: { ...data, id: userId }, // Include the userId in the payload
    });


  } catch (error) {
    dispatch({
      type: REGISTER_FAIL,
      msg: error.response.data,
    });
  }
};

  


export const login = (email, password)=>async (dispatch)=>{
    const config ={
        Headers:{
            'Content-Type' : 'application/json'
        }
    }

    await axios.get(`${process.env.REACT_APP_API_URL}api/login/${email}/`,config).then((res)=>{
        
        if(res.data.length === 0){
            alert("email not registered");
            dispatch({
                type: LOGIN_FAIL,
                msg: "email not registered"
            })
        }else{
            let emailinDB = res.data[0]?.email;
            let passwordinDB = res.data[0]?.password;

            if(emailinDB === email && passwordinDB === password){
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data[0]
                })
            }else{
                alert("wrong password");
                dispatch({
                    type: LOGIN_FAIL,
                    msg: "wrong password"
                })
            }
        }

    }).catch(err=>{
        dispatch({
            type: LOGIN_FAIL,
            msg: err.response.data
        })
    })

}


export const logout = ()=>async (dispatch)=>{
    dispatch({
        type: LOGOUT_SUCCESS,
    })
}