import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

function BulkPurchaseSection() {
  return (
      <Row className="justify-content-center my-5" style={{ color: 'white', backgroundColor: 'rgb(35, 35, 199)', padding:'50px' }}>
        <Col xs={12} md={8} lg={6} className="text-center" >
          <h2>Do you need bulk purchase from Kamsiparts Automotive?</h2>
          <p>Order in Bulk now</p>
          <Button variant="primary" href="https://www.kamsiparts.com/bulk-products-requests/">
          Order Now
          </Button>
        </Col>
      </Row>
  );
}

export default BulkPurchaseSection;
