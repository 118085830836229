import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../../actions/authActions";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import "./login.css";

const Login = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onClick = () => {
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("User is authenticated, redirecting...", isAuthenticated);
      window.location.replace("/");
    }
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <Navbar />
      <Container className="form-container">
        <Row className="justify-content-md-center">
          <Col xs={11} md={8} className="form-column">
            <h2>Login</h2>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  required
                />
              </Form.Group>

              <Button className="mt-3 login-button" variant="primary" onClick={onClick}>
                Login
              </Button>
            </Form>

            <p className="mt-3">
              Not have account ?{" "}
              <Link to={"/signup"}>
                <span>Register here</span>
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Login;
