import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import { deleteFromCart, deleteItemFromCart, getItemsCart } from "../../actions/cartActions";
import Navbar from "../../components/Navbar/Navbar";
// import styles from './styles.module.css';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from "../../components/Footer/Footer";

const CartView = () => {
  // Fetch user token from localStorage
  // const tokenString = localStorage.getItem('token');
  // const token = tokenString ? JSON.parse(tokenString) : null;
  // console.log('Parsed Token:', token);

  // Check if the user is logged in and has a role
  // const tokenReady = token !== null && token !== undefined && token.role && token.role.id !== undefined;
  // console.log('Token Ready:', tokenReady);  


  const location = useLocation();
  const navigate = useNavigate();
  const { cartId } = location.state;
  const { cartItem, cart } = useSelector((state) => state.cartReducer);
  const { token } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const [quantityUpdates, setQuantityUpdates] = useState({}); // State to track quantity updates
  const [totalPrice, setTotalPrice] = useState(0);

  

  const deleteItem = (id) => {
    dispatch(deleteFromCart(token?.id, cart?.quantity));
    dispatch(deleteItemFromCart(id));
    window.location.replace('/cartView');
  };

  const calculateDiscount = (quantity) => {
    let discount = 0;
    if (quantity >= 0 && quantity < 5) {
      discount = 0.03; // 3% discount
    } else if (quantity >= 5 && quantity < 10) {
      discount = 0.05; // 5% discount
    } else if (quantity >= 10) {
      discount = 0.07; // 7% discount
    }
    return discount;
  };
// CALCULATING TOTAL PRICE
  const calculateTotalPrice = () => {
    let total = 0;
    cartItem.forEach((item) => {
      const { product_details, quantity } = item;
      const updatedQuantity = quantityUpdates[item.id] !== undefined ? quantityUpdates[item.id] : quantity;
      const discount = calculateDiscount(updatedQuantity);
      const discountedPrice = product_details.price * (1 - discount);
      const discountAddUp = discountedPrice * updatedQuantity
      total += discountAddUp ;
    });
    const calculatedTotalPrice = total.toFixed(2);
    setTotalPrice(calculatedTotalPrice); // Update the state with the calculated total price
    return calculatedTotalPrice;
  };

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      dispatch(getItemsCart(cartId));
      setTotalPrice(calculateTotalPrice());
    }
  }, [dispatch, token, cartId]);
  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [cartItem, quantityUpdates]);

  const handleCheckout = () => {
    navigate("/checkout", { state: { cartId: cartId,  totalPrices: totalPrice} });
  };

  return (
    <>
      <Navbar />
      <Container>
        <h1 className="mt-3">Your Cart Items</h1>
        <Row >
          {cartItem.map((item, index) => {
            const { id, product_details, quantity } = item;
            const updatedQuantity = quantityUpdates[id] !== undefined ? quantityUpdates[id] : quantity;
            const discount = calculateDiscount(updatedQuantity);
            const discountedPrice = product_details.price * (1 - discount);
            const discountAddUp = discountedPrice * updatedQuantity;

            return (
              <Col key={index} sm={6} md={1} lg={3}>
                <Card className="shadow mt-4">
                  <Card.Body >
                    <Card.Title>{index + 1}.</Card.Title>
                    <Card.Img src={product_details.thumbnail} alt={`thumbnail-${index}`} style={{ height: '100px', objectFit: 'cover' }} />
                    <div className="d-flex flex-column">
                      <Card.Title>{product_details?.title}</Card.Title>
                      <Card.Text>
                      <div><strong>Price:</strong> ₦{discountedPrice.toFixed(2)} </div>
                      <div><strong>Total price for each discount:</strong> ₦{discountAddUp.toFixed(2)}</div>
                      <div> <strong>Total Price with Discount:</strong> ₦{discountedPrice.toFixed(2)}</div>
                      <div> <strong>Item Added:</strong> {quantity} X times</div>
                    </Card.Text>
   
                      {/* <input
                        type="number"
                        min="0"
                        value={updatedQuantity}
                        onChange={(e) => updateQuantity(id, parseInt(e.target.value))} f
                      /> */}
                      <Button variant="danger" onClick={() => deleteItem(id)}>
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className="mt-3 pb-4 pt-3 d-flex">
        <h3>Total Price: ₦{totalPrice}</h3>
        <Button className="ml-4 p-3" variant="primary" onClick={handleCheckout}>
          Proceed to Checkout
        </Button>
      </Container>
      <Footer />
    </>
  );
};

export default CartView;